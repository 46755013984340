import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="Wow! Wrong URL" />
      <section className="relative bg-black text-white">
        <div className="relative container text-center pt-32 md:pt-40 flex flex-col min-h-screen">
          <StaticImage
            src="../images/stickers/404.webp"
            // placeholder="tracedSVG"
            className="w-[240px] md:w-[400px] mx-auto"
            alt="404 Cards"
          />
          <div className="mx-auto md:pb-52">
            <h1 className="font-serif text-4xl md:text-6xl font-bold mb-4">
              Page not found
            </h1>
            <p className="text-xl mb-6">
              Oh wow! We couldn’t find what you were looking for.
            </p>
            <Link
              to="/"
              className="inline-block font-semibold relative text-white hover:text-black border-2 border-white px-6 py-3 before:transition-all before:ease-in-out before:h-full before:w-full before:transform before:top-0 before:left-0 before:absolute before:bg-white before:scale-y-0 before:origin-bottom hover:before:scale-y-100 before:z-[0]"
            >
              <span className="z-10 relative">Back to Home</span>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
